

const children = [
  {
    path: '/',
    name: 'init client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/fragment/home_view.vue')
  },
  {
    path: '/user/profile',
    name: 'profile client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/fragment/user_profile.vue')
  },
  //produts
  {
    path: '/product/:product_id',
    name: 'product info client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/fragment/product_info.vue')
  },
  //stores
  {
    path: '/store/request',
    name: 'request store client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/stores/store_request.vue')
  },
  {
    path: '/stores',
    name: 'stores client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/stores/store_list.vue')
  },
  {
    path: '/.:codename',
    name: 'store profile client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/stores/store_profile.vue')
  },
  {
    path: '/store/product',
    name: 'store product client',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/stores/store_list_product.vue')
  },

];

export default children;