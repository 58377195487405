import Vue from 'vue'
import VueRouter from 'vue-router'
import route_admin from "./route_admin";
import route_client from "./route_client";
import route_client_sales from "./route_client_sales";
import route_login from "./route_login";
import route_store_manager from "./route_store_manager";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'client app',
    component: () => import(/* webpackChunkName: "about" */ '../views/client/app_view.vue'),
    children: [...route_client, ...route_client_sales],
  },
  {
    path: '/login/app',
    name: 'login app',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/app_login.vue'),
    children: route_login,
  },
  {
    path: '/admin/app',
    name: 'admin app',
    component: () => import(/* webpackChunkName: "about" */ '../views/super_admin/app_view.vue'),
    children: route_admin,
  },
  {
    path: '/sm/app',
    name: 'store app',
    component: () => import(/* webpackChunkName: "about" */ '../views/store_manager/app_view.vue'),
    children: route_store_manager,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
