

const children = [
  {
    path: '/shoppingcart',
    name: 'shoppingcart list',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/sales/shoppingcart_list.vue')
  },
  {
    path: '/buy/:store_id/:products_str',
    name: 'make purchase',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/sales/make_purchase.vue')
  },
  {
    path: '/buy/type/send/:store_id/:products_str',
    name: 'type send',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/sales/type_send.vue')
  },

];

export default children;