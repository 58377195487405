/********LIBRERIA********* */
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Vuesax from 'vuesax';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
//import util
import UtilGlobal from './utils/util_global';
/********LIBRERIA********* */

/********CSSS********* */
import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
/********CSSS********* */

/********FUNSION********* */
moment.locale('es');

/********FUNcION********* */
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.use(VueAxios, axios);
Vue.use(Vuesax);

/********CARGAR FUNCIONES GLOBALES UTILS********* */
Vue.use(UtilGlobal);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
