

const children = [
  {
    path: '/admin/home',
    name: 'home admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/fragment/dashboard_view.vue')
  },
  //user
  {
    path: '/admin/user/accounts',
    name: 'accounts admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/fragment/accounts_view.vue')
  },
  {
    path: '/admin/user/roles',
    name: 'roles admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/fragment/roles_view.vue')
  },
  {
    path: '/admin/user/recursos',
    name: 'resources admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/fragment/resources_view.vue')
  },
  {
    path: '/admin/user/aciones',
    name: 'actions admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/fragment/actions_view.vue')
  },
  {
    path: '/admin/user/profile',
    name: 'profile admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/client/fragment/user_profile.vue')
  },
  //stores
  {
    path: '/admin/store/requests',
    name: 'requests admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/stores/requests.vue')
  },
  {
    path: '/admin/store/stores',
    name: 'stores admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/stores/stores.vue')
  },
  {
    path: '/admin/store/categories',
    name: 'categories admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/stores/categories.vue')
  },
  {
    path: '/admin/store/plans',
    name: 'plans admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/stores/plans.vue')
  },
  {
    path: '/admin/store/planpermissions',
    name: 'planpermissions admin',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/super_admin/stores/planpermissions.vue')
  },



];

export default children;