

const children = [
  {
    path: '/sm/:store_id/home',
    name: 'home sm',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/store_manager/fragment/dashboard_view.vue')
  },
  {
    path: '/sm/:store_id/profile',
    name: 'info sm',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/store_manager/fragment/store_info_view.vue')
  },
  {
    path: '/sm/:store_id/products',
    name: 'products sm',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/store_manager/fragment/products_view.vue')
  },
  {
    path: '/sm/:store_id/employees',
    name: 'employees sm',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/store_manager/fragment/employees_view.vue')
  },

];

export default children;