const UtilGlobal = {};
UtilGlobal.install = function (Vue) {
  Vue.prototype.notify = function (res) {
    if (res.type == "error") res.type = "danger";
    this.$vs.notification({
      color: res.type,
      position: "top-right",
      title: `¡Notificación!`,
      text: res.message,
    });
  };
  Vue.prototype.alertMessage = function (message, options, call) {
    options = options ?? {};
    this.$root.$emit("dialog_alert_message", {
      message: message ?? "",
      ...options,
      call: call,
      icon: options.icon,
    });
  };
  let loading;
  //   let loadings = [];
  Vue.prototype.onLoading = function (status) {
    if (status == true) {
      try {
        if (loading != undefined) loading.close();
      } catch (error) {
        loading = undefined;
      }
      loading = this.$vs.loading();
    }
    if (status == false) {
      loading.close();
    }
  };
  Vue.prototype.alertCloseSession = function () {
    this.$root.$emit("dialog_close_sessión", {});
  };
  //update user
  Vue.prototype.onUpdateUser = function (call) {
    this.$root.$on("update_global_user", async () => {
      if (call != undefined) call();
    });
  };
  Vue.prototype.emitUpdateUser = function () {
    this.$root.$emit("update_global_user", {});
  };
  //update shoppingcart
  Vue.prototype.onShoppingcart = function (call) {
    this.$root.$on("shoppingcart_count", async () => {
      if (call != undefined) call();
    });
  };
  Vue.prototype.emitShoppingcart = function () {
    this.$root.$emit("shoppingcart_count", {});
  };
};

export default UtilGlobal;
