

const children = [
  {
    path: '/login',
    name: 'login',
    props: true,
    meta: {},
    component: () => import('../views/login/fragment/login.vue')
  },
  {
    path: '/login/confirm/account/:token/:message',
    name: 'confirm account login',
    props: true,
    meta: {},
    component: () => import('../views/login/fragment/confirm_account.vue')
  },
  {
    path: '/login/change/password/:token/:message',
    name: 'change password login',
    props: true,
    meta: {},
    component: () => import('../views/login/fragment/change_password.vue')
  },
  {
    path: '/login/recover/password',
    name: 'recover password login',
    props: true,
    meta: {},
    component: () => import('../views/login/fragment/recover_password.vue')
  },

];

export default children;